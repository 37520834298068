import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  declare itemTargets: HTMLElement[]
  declare imageTarget: HTMLElement
  static targets = ["item", "image"]

  connect(): void {
    const firstItem = this.itemTargets[0]
    firstItem.dataset.active = "true"
  }

  toggle(event) {
    if (this.imageTarget) {
      this.imageTarget.src = `/images/illustrations/a-${event.params["index"]}.svg`
      this.imageTarget.alt = event.params["alt"]
    }

    this.itemTargets.forEach((item, i) => {
      if (event.params["index"] === i + 1) {
        item.dataset.active = "true"
      } else {
        item.dataset.active = "false"
      }
    })
  }
}
